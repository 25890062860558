import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
import OpportunityService from "../../service/opportunity/OpportunityService";
import IndexTable from "../../components/common/IndexTable";
import { toast } from "react-toastify";

import {
	Button,
	Menu,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	FormGroup,
	FormControlLabel,
	Switch,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FaPlus } from "react-icons/fa6";
const theme = createTheme({
	palette: {
		default: {
			main: "#ffffff",
			light: "#f7fafc",
			dark: "##f7fafc",
			contrastText: "#2d3748",
		},
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
		success: {
			main: "#22c55e",
			light: "#86efac",
			dark: "#16a34a",
			contrastText: "#fff",
		},
		error: {
			main: "#ef4444",
			light: "#fca5a5",
			dark: "#dc2626",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

function Opportunities({ loading, setLoading }) {
	const pageTitle = "Opportunities | RTO Mobile";

	const [opportunitiesData, setOpportunitiesData] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	const IndexView = {
		page: 1,
		limit: 10,
		sort: "id:desc",
		filter: {},
		load_initial_data: true,
		load_initial_filters: true,
	};

	const fetchOpportunities = async (userFilter = "") => {
		setLoading(true);
		try {
			const filterOptions = { ...IndexView.filter };
			if (userFilter) {
				filterOptions.assigned_to_id = userFilter;
			}

			const response = await OpportunityService.view({
				...IndexView,
				filter: filterOptions,
			});
			setOpportunitiesData(response);
			setIsDataLoaded(true);

			// Update assignedToOptions if it's in the response
			if (
				response.data &&
				response.data.columns &&
				response.data.columns.assigned_to_fullname_first
			) {
				setAssignedToOptions(
					response.data.columns.assigned_to_fullname_first.data || []
				);
			}

			return response;
		} catch (error) {
			console.error("Error fetching opportunities:", error);
			toast.error("Failed to fetch opportunities");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOpportunities();
	}, []);

	const [activeView, setActiveView] = useState(() => {
		// Initialize state from localStorage
		return localStorage.getItem("activeView") || "list";
	});
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		// Save activeView to localStorage whenever it changes
		localStorage.setItem("activeView", activeView);
	}, [activeView]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (view) => {
		setAnchorEl(null);
		if (view) {
			setActiveView(view);
		}
	};

	const [columns, setColumns] = useState({
		"Phone (COLD)": { items: [] },
		"InPerson (COLD)": { items: [] },
		"Referrals (COLD)": { items: [] },
		"SDR (COLD)": { items: [] },
		"Proposing (STALLED)": { items: [] },
		"Proposing (WARM)": { items: [] },
		"Paperwork Pending": { items: [] },
		"Closed Won (MTD)": { items: [] },
		"Closed Lost": { items: [] },
		"Closed Won (YTD)": { items: [] },
	});

	const formatOpportunitiesData = (data) => {
		const columnMap = {
			"Phone (COLD)": [],
			"InPerson (COLD)": [],
			"Referrals (COLD)": [],
			"SDR (COLD)": [],
			"Proposing (STALLED)": [],
			"Proposing (WARM)": [],
			"Paperwork Pending": [],
			"Closed Won (MTD)": [],
			"Closed Lost": [],
			"Closed Won (YTD)": [],
		};

		if (Array.isArray(data)) {
			data.forEach((opportunity, index) => {
				const columnKey = opportunity.opportunities_stage_name;
				if (columnMap.hasOwnProperty(columnKey)) {
					columnMap[columnKey].push({
						id: `item${index + 1}`,
						content: opportunity.model_name,
					});
				}
			});
		} else {
			console.error("Expected data to be an array, but received:", data);
		}

		const formattedColumns = {};
		Object.keys(columnMap).forEach((key) => {
			formattedColumns[key] = { items: columnMap[key] };
		});

		return formattedColumns;
	};

	useEffect(() => {
		if (
			opportunitiesData &&
			opportunitiesData.data &&
			opportunitiesData.data.data
		) {
			if (Array.isArray(opportunitiesData.data.data)) {
				const formattedColumns = formatOpportunitiesData(
					opportunitiesData.data.data
				);
				setColumns(formattedColumns);
			}
		}
	}, [opportunitiesData]);

	const onDragEnd = (result) => {
		if (!result.destination) return;
		const { source, destination } = result;

		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems,
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems,
				},
			});
		} else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
					...column,
					items: copiedItems,
				},
			});
		}
	};

	const [selectedUser, setSelectedUser] = useState("");
	const [filterList, setFilterList] = useState({});
	const [assignedToOptions, setAssignedToOptions] = useState([]);

	const updateAssignedToOptions = (options) => {
		setAssignedToOptions(options);
	};

	const handleUserChange = (event) => {
		const newValue = event.target.value;
		console.log("Selected user:", newValue);
		setSelectedUser(newValue);

		const newFilterList = { ...filterList };
		if (newValue) {
			newFilterList.assigned_to_id = newValue;
		} else {
			delete newFilterList.assigned_to_id;
		}
		setFilterList(newFilterList);

		if (activeView === "pipeline") {
			fetchOpportunities(newValue);
		}
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>{pageTitle}</title>
					<meta name="robots" content="noindex,nofollow"></meta>
					<meta property="og:title" content={pageTitle} />
					<meta name="twitter:title" content={pageTitle} />
				</Helmet>
				<div className="rto_form-sections">
					<div
						className="rto_content-div"
						style={{ maxWidth: "1600px" }}
					>
						<Breadcrumb
							listProps={{
								className: "w-100 justify-content-end",
							}}
						>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/dashboard" }}
							>
								Dashboard
							</Breadcrumb.Item>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/admin" }}
							>
								Admin
							</Breadcrumb.Item>
							<Breadcrumb.Item active>
								Opportunities
							</Breadcrumb.Item>
						</Breadcrumb>
						<div className="rto_title-div">
							<h1 className="rto_page-title">Opportunities</h1>
						</div>
						<p className="text-center">
							<Link
								to="/opportunities/create"
								className="btn btn-primary"
							>
								Create
							</Link>
						</p>

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								mb: 3,
							}}
						>
							{activeView === "pipeline" && (
								<FormGroup>
									<FormControlLabel
										control={<Switch defaultChecked />}
										label="Collapse All"
									/>
								</FormGroup>
							)}

							<FormControl
								sx={{ minWidth: 120, mr: 2 }}
								size="small"
							>
								<InputLabel id="user-select-label">
									User
								</InputLabel>
								<Select
									labelId="user-select-label"
									id="user-select"
									value={selectedUser}
									onChange={handleUserChange}
									label="User"
								>
									<MenuItem value="">
										--- All Users ---
									</MenuItem>
									{assignedToOptions.map((option) => (
										<MenuItem
											key={option.id}
											value={option.id.toString()}
										>
											{option.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<Button
								variant="contained"
								disableElevation
								color="primary"
								onClick={handleClick}
								endIcon={<ArrowDropDownIcon />}
								sx={{ mb: 3, py: 1 }}
							>
								<span className="font-normal text-base">
									{activeView === "list"
										? "List View"
										: "Pipeline View"}
								</span>
							</Button>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={() => handleClose()}
							>
								<MenuItem onClick={() => handleClose("list")}>
									List View
								</MenuItem>
								<MenuItem
									onClick={() => handleClose("pipeline")}
								>
									Pipeline View
								</MenuItem>
							</Menu>
						</div>

						{activeView === "list" ? (
							<div
								className="card card-outline card-primary"
								style={{
									borderTop: "3px solid #00aaff",
									boxShadow:
										"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
								}}
							>
								<div className="card-body">
									{isDataLoaded && (
										<IndexTable
											model={"opportunities"}
											loading={loading}
											setLoading={setLoading}
											service={OpportunityService}
											title="Opportunities"
											actionButtons={{
												updateLink: {
													path: "/opportunities",
												},
												// updateModal: {
												// 	enabled: true,
												// 	path: "/leads/update",
												// },
												// create: {
												// 	enabled: true,
												// 	path: "/leads/create",
												// },
											}}
											labels={[
												{
													id: "id",
													label: "ID",
												},
												{
													id: "name",
													label: "Name",
												},
												{
													id: "model_name",
													label: "Model",
													model_link_alt: true,
												},
												{
													id: "store_count",
													label: "Store Count",
												},
												{
													id: "opportunities_stage_name",
													label: "Stage",
												},
												{
													id: "close_date",
													label: "Close Date",
												},
												{
													id: "description",
													label: "Description",
													initialDisplay: false,
												},
												{
													// duplicate hidden column to adjust the link
													id: "model_id",
													label: "Model ID",
													excluded: true,
												},
												{
													// duplicate hidden column to adjust the link
													id: "model",
													label: "Model",
													excluded: true,
												},
												{
													// duplicate hidden column to adjust the link
													id: "model_name",
													label: "Model Name",
													excluded: true,
												},
												{
													id: "assigned_to_fullname_first",
													label: "Assigned To",
												},
												{
													id: "created_at",
													label: "Created At",
												},
												// {
												// 	id: "created_to_fullname_first",
												// 	label: "Created By",
												// 	initialDisplay: false,
												// },
											]}
											apiBase="/opportunity"
											successRedirectBase="opportunities"
											externalData={opportunitiesData}
											selectedUser={selectedUser}
											externalFilterList={filterList}
											onUserChange={handleUserChange}
											onUpdateAssignedToOptions={
												updateAssignedToOptions
											}
										/>
									)}
								</div>
							</div>
						) : (
							<div
								className="card card-outline card-primary"
								style={{
									borderTop: "3px solid #00aaff",
									boxShadow:
										"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
								}}
							>
								<div
									className="card-body"
									style={{ overflow: "auto" }}
								>
									<DragDropContext onDragEnd={onDragEnd}>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												height: "100%",
											}}
										>
											{Object.entries(columns).map(
												([columnId, column]) => (
													<div
														className="border rounded-lg"
														key={columnId}
														style={{
															display: "flex",
															flexDirection:
																"column",
															alignItems:
																"center",
															margin: 2.5,
														}}
													>
														<div className="rounded-t border-b w-full bg-slate-50 py-[1.25rem] px-[.75rem]">
															<h5>{columnId}</h5>
															<span
																className="d-block small"
																style={{
																	whiteSpace:
																		"nowrap",
																	opacity:
																		".7",
																}}
															>
																116
																OPPORTUNITIES -
																$4,020.00
															</span>
														</div>
														<div
															style={
																{
																	// margin: 8,
																}
															}
														>
															<Droppable
																droppableId={
																	columnId
																}
																key={columnId}
															>
																{(
																	provided,
																	snapshot
																) => (
																	<div
																		{...provided.droppableProps}
																		ref={
																			provided.innerRef
																		}
																		style={{
																			background:
																				snapshot.isDraggingOver
																					? "#bae6fd"
																					: "white",
																			padding: 0,
																			width: 250,
																			minHeight: 500,
																			// borderRadius: 8,
																		}}
																	>
																		{column.items.map(
																			(
																				item,
																				index
																			) => (
																				<Draggable
																					key={
																						item.id
																					}
																					draggableId={
																						item.id
																					}
																					index={
																						index
																					}
																				>
																					{(
																						provided,
																						snapshot
																					) => (
																						<div
																							ref={
																								provided.innerRef
																							}
																							{...provided.draggableProps}
																							{...provided.dragHandleProps}
																							className="border-b"
																							style={{
																								userSelect:
																									"none",
																								margin: "0 0 0px 0",
																								minHeight:
																									"50px",
																								backgroundColor:
																									snapshot.isDragging
																										? "grey"
																										: "white",
																								color: "black",
																								// borderRadius: 4,
																								...provided
																									.draggableProps
																									.style,
																							}}
																						>
																							<div
																								style={{
																									padding:
																										"16px",
																									position:
																										"relative",
																								}}
																							>
																								{
																									item.content
																								}
																								<FaPlus
																									style={{
																										color: "#00ABF9",
																										position:
																											"absolute",
																										bottom: "5px",
																										right: "5px",
																										cursor: "pointer",
																									}}
																								/>
																							</div>
																						</div>
																					)}
																				</Draggable>
																			)
																		)}
																		{
																			provided.placeholder
																		}
																	</div>
																)}
															</Droppable>
														</div>
													</div>
												)
											)}
										</div>
									</DragDropContext>
								</div>
							</div>
						)}
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}

export default Opportunities;
