import ApiService from "../ApiService";

/**
 * @see /docs/api/index.html#Reminder
 */

const OpportunityService = {
	/**
	 *
	 * @param {OpportunityView} OpportunityView
	 * @returns
	 */
	view: (OpportunityView) => {
		return ApiService.post("/opportunity/index", OpportunityView).catch(
			(error) => {
				console.log(error);
				return error;
			}
		);
	},

	/**
	 * Data filter by ID
	 * @param {int} id
	 * @see /docs/api/index.html#jump-Opportunities-OpportunityFetchById
	 * @returns JSON
	 */
	fetchById: (id) => {
		return ApiService.get("/opportunity/fetch-by-id", { id: id }).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * @param OpportunityCreate
	 * @see /docs/api/index.html#jump-Opportunity-apiNew_2Fopportunity_2Fcreate
	 * @returns JSON
	 */
	create: (OpportunityCreate) => {
		return ApiService.post("/opportunity/create", OpportunityCreate).catch(
			(error) => {
				console.log(error);
			}
		);
	},

	/**
	 * Update service
	 * @param json data {
	 * 		opportunityId : 1,
	 * 		company_name: 'xxxx',
	 * 		address_1: 'ADD1' ...
	 * }
	 * @see /docs/api/index.html#jump-Opportunities-OpportunityUpdate
	 * @returns
	 */
	update: (data) => {
		return ApiService.post("/opportunity/update", data).catch((error) => {
			console.log(error);
		});
	},

	/**
	 *
	 * @param {Integer} opportunityId
	 * @see /docs/api/index.html#jump-Opportunities-OpportunityDelete
	 * @returns
	 */
	delete: (opportunityId) => {
		return ApiService.post("/opportunity/delete", {
			opportunityId: opportunityId,
		}).catch((error) => {
			console.log(error);
		});
	},

	/**
	 * @see /docs/api/index.html#jump-Opportunities-GetUsersBySysRtoIsoAdminRole
	 * @returns
	 */
	getUsersBySysRtoIsoAdminRole: () => {
		return ApiService.get(
			"/opportunity/get-users-by-sys-rto-iso-admin-role"
		).catch((error) => {
			console.log(error);
		});
	},
};

export default OpportunityService;
